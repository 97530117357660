import React, { useEffect, useImperativeHandle } from 'react';
import { Form, Input, InputNumber, message } from 'antd';

import {
  BlueprintDataFormProps,
  BlueprintDataFormRef,
} from '@lib/pages/blueprint-data/components/form';

const ChatBlueprintForm = React.forwardRef<BlueprintDataFormRef, BlueprintDataFormProps>(
  ({ data }, ref) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
      form.setFieldsValue(data);
    }, [data]);

    useImperativeHandle(ref, () => ({
      onSave: () =>
        form.validateFields().then(
          (value) => {
            return value;
          },
          (err) => {
            message.error(err.errorFields[0].errors[0]);
            return null;
          },
        ),
    }));

    return (
      <Form layout="vertical" form={form} style={{ maxWidth: 500 }}>
        <Form.Item name="id" label="Id" rules={[{ required: true, message: 'Id is required!' }]}>
          <Input placeholder="Input Id" />
        </Form.Item>
        <Form.Item name="historyLimit" label="History Limit">
          <InputNumber
            placeholder="Input history limit"
            style={{ width: '100%' }}
            min={1}
            max={100}
          />
        </Form.Item>
        <Form.Item name="messageLength" label="Message Length">
          <InputNumber placeholder="Input message length" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="sendCooldown" label="Send Cooldown">
          <InputNumber placeholder="Input send cooldown" style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    );
  },
);

export default ChatBlueprintForm;
