import React, { FC } from 'react';

import BaseApp from '@lib/components/app';
import { AppProvider } from '@lib/providers/app';

import ChatBlueprintForm from '@pages/blueprint-data/forms/ChatBlueprintForm';
import { appRoutes } from '@routes/routes';

import version from '../../version.json';

const App: FC = () => {
  return (
    <AppProvider
      value={{
        version: version.version,
        routes: appRoutes,
        blueprint: {
          formComponents: {
            'com.alleylabs.gear.blueprint.ChatBlueprint': ChatBlueprintForm,
          },
        },
        docs: {
          userGuidePath: '/user-guide/',
          devGuidePath: '/docs/',
        },
      }}
    >
      <BaseApp />
    </AppProvider>
  );
};

export default App;
