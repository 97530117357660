import React from 'react';
import { MailOutlined, SearchOutlined, ToolOutlined } from '@ant-design/icons';

import CsToolInboxHistoryScreen from '@lib/pages/cstool/inbox/history/lazy';
import CsToolInboxScreen from '@lib/pages/cstool/inbox/v2/lazy';
import CsToolSearchUserScreen from '@lib/pages/cstool/search-user/lazy';
import {
  appRoutes as baseAppRoutes,
  CsInboxHistoryLocation,
  CsInboxLocation,
  CsSearchUserLocation,
  RouteItem,
} from '@lib/routes/routes';
import { UserRole } from '@lib/services/_api';

export const appRoutes: RouteItem[] = [
  ...baseAppRoutes.slice(0, -1),
  {
    name: 'csTool',
    title: 'CS Tool',
    icon: <ToolOutlined />,
    children: [
      {
        name: 'searchUser',
        title: 'Search User',
        icon: <SearchOutlined />,
        component: CsToolSearchUserScreen,
        path: CsSearchUserLocation.path,
        exact: true,
        role: UserRole.CsViewer,
      },
      // {
      //   name: 'mailTemplate',
      //   title: 'Mail Template',
      //   icon: <MailOutlined />,
      //   component: CsToolMailTemplateScreen,
      //   path: CsMailTemplateLocation.path,
      //   role: UserRole.Member,
      // },
      {
        name: 'inbox',
        title: 'Inbox',
        icon: <MailOutlined />,
        component: CsToolInboxScreen,
        path: CsInboxLocation.path,
        exact: true,
        role: UserRole.CsEditor,
      },
      {
        name: 'inboxHistory',
        title: 'Inbox History',
        icon: <MailOutlined />,
        component: CsToolInboxHistoryScreen,
        path: CsInboxHistoryLocation.path,
        exact: true,
        hideInMenu: true,
        role: UserRole.CsViewer,
      },
    ],
  },
];
