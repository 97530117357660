import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { selector, useRecoilState } from 'recoil';

import { LoginLocation } from '@lib/routes/routes';
import authService from '@lib/services/auth';
import { GearUser, UserRoles } from '@lib/services/models';
import { useQuery } from '@lib/utils/use-query';

export const TOKEN_KEY = 'GEAR_TOKEN';

const tokenState = selector<string | undefined>({
  key: 'tokenState',
  get() {
    return localStorage.getItem(TOKEN_KEY) ?? undefined;
  },
  set(opts, data) {
    if (data) {
      localStorage.setItem(TOKEN_KEY, data as string);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  },
});

const useAuthState = () => {
  const [token, _setToken] = useRecoilState(tokenState);
  const [authenticated, setAuthenticated] = useState(!!token);
  const [currentUser, setCurrentUser] = useState<GearUser>();
  const [userRoles, setUserRoles] = useState<UserRoles>();

  const reset = async () => {
    const res = await authService.me();
    const { ok, data } = res;
    if (ok && data) {
      const { user, roles } = data;

      const _userRoles = roles.reduce<UserRoles>((all, item) => {
        all[item.role] = item.environments ?? [];
        return all;
      }, {});

      setCurrentUser(user);
      setUserRoles(_userRoles);
      setAuthenticated(!!res.data);
    } else {
      setCurrentUser(undefined);
      setUserRoles(undefined);
      setAuthenticated(false);
    }
  };

  const setToken = (newToken?: string) => {
    _setToken(newToken);
    if (newToken !== token) {
      reset();
    }
  };

  useEffect(() => {
    reset();
  }, []);

  return {
    token,
    authenticated,
    currentUser,
    userRoles,
    reset,
    setToken,
  };
};

export type UseAuth = ReturnType<typeof useAuthState>;

const authContext = createContext<UseAuth | null>(null);

const { Provider } = authContext;

export const AuthProvider: FC = ({ children }) => {
  const auth = useAuthState();
  const history = useHistory();
  const query = useQuery();

  const { authenticated } = auth;

  useEffect(() => {
    const isLoginRoute = history.location.pathname === LoginLocation.path;
    if (!authenticated && !isLoginRoute) {
      const next = encodeURIComponent(`${history.location.pathname}${history.location.search}`);
      history.push(`${LoginLocation.path}?next=${next}`);
    } else if (authenticated && isLoginRoute) {
      const next = decodeURIComponent(query.get('next') || '/');
      const [pathname, search] = next.split('?');
      history.push({ pathname, search });
    }
  }, [authenticated]);

  return <Provider value={auth}>{children}</Provider>;
};

export const useAuth = () => {
  const auth = useContext(authContext);
  if (!auth) {
    throw new Error('useAuth must be used inside an AuthProvider');
  }

  return auth;
};
